<template>
  <!-- <v-container fluid> -->
    <v-bottom-navigation
        app
        :value="selectIndex"
        class="bottom_bg"
        grow
        dark>
        <v-btn value="0" to="/home">
          <span>{{ $t('home.bottom_nav_0') }}</span>
          <v-badge color="success" dot>
            <v-icon>mdi-home-analytics</v-icon>
          </v-badge>
        </v-btn>

        <v-btn value="1" to="/accept">
          <span>{{ $t('home.bottom_nav_1') }}</span>
          <v-badge color="error" :content="$store.state.deliveryCount.badge_0" v-if="$store.state.deliveryCount.badge_0">
            <v-icon>mdi-file-document-multiple-outline</v-icon>
          </v-badge>
          <v-icon v-else>mdi-file-document-multiple-outline</v-icon>
        </v-btn>

        <v-btn value="2" to="/processing">
          <span>{{ $t('home.bottom_nav_2') }}</span>
          <v-badge color="error" :content="$store.state.deliveryCount.badge_1" v-if="$store.state.deliveryCount.badge_1">
            <v-icon>mdi-file-document-refresh-outline</v-icon>
          </v-badge>
          <v-icon v-else>mdi-file-document-refresh-outline</v-icon>
        </v-btn>

        <v-btn value="3" to="/ready">
          <span>{{ $t('home.bottom_nav_3') }}</span>
          <v-badge color="error" :content="$store.state.deliveryCount.badge_2" v-if="$store.state.deliveryCount.badge_2">
            <v-icon>mdi-file-document-arrow-right-outline</v-icon>
          </v-badge>
          <v-icon v-else>mdi-file-document-arrow-right-outline</v-icon>
        </v-btn>

        <v-btn value="4" to="/complete">
          <span>{{ $t('home.bottom_nav_4') }}</span>
          <v-icon>mdi-file-document-check-outline</v-icon>
        </v-btn>

        <v-btn value="5" to="/cancel">
          <span>{{ $t('home.bottom_nav_5') }}</span>
          <v-icon>mdi-file-document-remove-outline</v-icon>
        </v-btn>
    </v-bottom-navigation>
  <!-- </v-container> -->
</template>

<script>

export default {
  name: "Bottom",
  data() {
    return {
      selectIndex: 0
    };
  },
  async mounted() {
    await this.$store.dispatch("setDeliveryStatusCount");
  },
  // methods: {
  // }
}

</script>

<style scoped>
.bottom_bg {
    /* background-color: #303F9F; */
    color: #FFD600;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    font-size: 0.9rem !important;
}
/* .v-bottom-navigation {
  justify-content: start;
  overflow-x: scroll;
}
.v-btn__content {
    flex-direction: column-reverse;
} */
</style>