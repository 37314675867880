<template>
    <div class="app text-center">
        <v-dialog
        v-model="show"
        :width="width ?? 400">
        <v-card>
            <v-card-title class="text-h5 primary white--text">{{ $t('showimage.title') }}</v-card-title>

            <v-card-text class="pa-1 text-center font-weight-bold">
                {{ msg }}
                <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="getImageUrl()"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1">
                <template v-slot:placeholder>
                    <v-row
                    class="fill-height ma-0"
                    align-center
                    justify="center">
                    <v-progress-circular v-show="loading"
                        indeterminate
                        color="gray lighten-5"
                    ></v-progress-circular>
                    </v-row>
                </template>
                </v-img>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="white--text" color="primary" dark @click="onOkClick" width="100">{{ $t('showimage.ok_button') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { imageUrl } from "@/services/constants";

export default {
  name: "ShowImage",
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onOkClick() {
      this.show = false;
      if (this.callBack != null) {
        this.callBack();
      }
    },
    getImageUrl() {
      if (this.imageName.indexOf("http") == -1)
        return `${imageUrl}/${this.imageName}`;
      else
        return `${this.imageName}`;
    },
  },
  props: {
     value: Boolean,
     msg: String,
     imageName: String,
     width: Number,
     callBack: null
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform:none !important;
}
</style>